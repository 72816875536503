import React, { Component } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import MovLink from "../components/movlink";
// import { FaRegClock } from "react-icons/fa";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import Layout from "../layouts";
import { Helmet } from "react-helmet";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
// import { rhythm } from "../utils/typography";

import { ShowGrid } from "../components/pageLayout/ShowGrid";
import { ShowYearLinks } from "../components/pageLayout/ShowYearLinks";

// import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player";

import {
  GridHeader,
  GridBoxContainer,
  PageHeader,
  PageSpacing,
  PaddedMobile,
} from "../utils/styles";

export const videoStyle = css`
  div {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  div iframe,
  div object,
  div embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ShowGridBox = css`
  overflow: hidden;
  margin: 0 auto;
  max-width: 960px;
  background: #efefef;
  a > div {
    height: 110px;
    @media (min-width: 40em) {
      height: 150px;
    }
    @media (min-width: 52em) {
      height: 130px;
    }
    @media (min-width: 64em) {
      height: 180px;
    }
  }
  h3 {
    margin-bottom: auto;
  }
  a {
    text-decoration: none;
  }
`;

class Students extends Component {
  render() {
    const data = this.props.data;

    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | Students`}
          meta={[{ name: "description", content: "students" }]}
        />

        <Flex
          mx={[-1, -1, -2]}
          flexWrap="wrap"
          css={[PageSpacing, PaddedMobile]}
        >
          <Box width={1} px={[1, 1, 2]} pb={4}>
            <h1 ref={this.myDivToFocus} css={PageHeader}>
              MA:UX Graduate Show 2019
            </h1>
          </Box>
          <Box width={[1, 2 / 3, 3 / 5]} px={[1, 1, 2]} pr={2} pb={4}>
            <p>
              At a time of profound ecological and political crisis MA:UX
              students work hard to think beyond screens and devices, instead
              they focus attention on how human behaviour is shaped by
              technologies, and how design works to question existing paradigms,
              and configure new discourses. Above all students seek to be
              exploratory, experimental and expressive in the ways they apply
              their learning in the world.
            </p>
          </Box>
          <Box width={[1, 1 / 3, 2 / 5]} px={[1, 1, 2]} pb={4}>
            <ShowYearLinks />
          </Box>
          <ShowGrid students={data.allWordpressWpStudent.edges} />

          <Box width={1} px={[1, 1, 2]} pt={4} pb={4}>
            {/* <img src="maux.jpg" /> */}
            <ReactPlayer
              css={videoStyle}
              url="https://vimeo.com/377073189/04c1ee25cd"
              width="100%"
              height="100%"
              playing={false}
              loop={false}
              controls={true}
            />
          </Box>
        </Flex>
      </Layout>
    );
  }
}

export default Students;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressWpStudent(
      sort: { fields: title, order: ASC }
      filter: {
        acf: {
          start_year: { eq: "2018" }
          exclude_from_show: { ne: "exclude" }
        }
      }
    ) {
      edges {
        node {
          title
          slug
          acf {
            start_year
            final_major_project {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
